import {
  Stack,
  Text,
  DetailsList,
  SelectionMode,
  Link,
  DetailsListLayoutMode,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import Pagination from "../../Common/Pagination/Pagination";
import Page from "../../Common/Page/Page";
import useJuryListPage from "./useJuryListPage";
import JuryListFilters from "./JuryListFilters";
import CreateLink from "../../Common/CreateLink/CreateLink";
import tools from "../../utils/tools";

import { commonButtonStyles } from "../../styles/index";
import Can from "../../Common/Can/Can";
import { branchAccessEnum, userAccessEnum } from "../../config/accessEnum";
import useLabelSettings from "../LabelSettingsDetail/useLabelSettings";
import { DateTime } from "luxon";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import JuryStatusBadge from "../../Common/JuryStatusBadge/JuryStatusBadge";
import useAuth from "../../hooks/useAuth";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import useInfoProfilUser from "../../hooks/useInfoProfilUser";

function JuryList() {
  const { auth } = useAuth();
  const { jurys, optionsFilters, actions: actionsPage } = useJuryListPage();
  const { labels } = useLabelSettings();
  const { actions: accessActions } = useAccessSettings();
  const { isAdmin } = useInfoProfilUser();

  const [userSelectedBranch]: any = useAtom(userSelectedBranchAtom);

  const canInscrireCandidatureEvenIfDateInscriptionEchuProfil =
    auth.user.est_administrateur ||
    userSelectedBranch.profils.includes("ADMINISTRATEUR_BRANCHE");

  const items = jurys?.data || [];

  const columns = [
    {
      key: "1",
      name: "Date",
      fieldName: "date",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("date_jury");
      },
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.date_jury)}</Stack>
      ),
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "date_jury"),
    },
    {
      key: "2",
      name: "Statut",
      fieldName: "statut",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      // showSortIconWhenUnsorted: true,
      onRender: (item: any) => <JuryStatusBadge statut={item.statut} />,
    },
    {
      key: "3",
      name: "Libellé",
      fieldName: "libelle",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {
        actionsPage.sortColumn("libelle");
      },
      onRender: (item: any) => <Text>{item.libelle}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "libelle"),
    },
    {
      key: "4",
      name: "Date limite d'inscription",
      fieldName: "date_limite",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {
        actionsPage.sortColumn("date_limite_inscription_candidat");
      },
      onRender: (item: any) => (
        <Stack>
          {tools.reformateDateFromJsDate(item.date_limite_inscription_candidat)}
        </Stack>
      ),
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "date_limite_inscription_candidat"),
    },
    {
      key: "5",
      name: "Places restantes",
      fieldName: "nombre_de_places_restantes",
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      onRender: (item: any) => <Text>{item.nombre_de_places_restantes}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("nombre_de_places_restantes");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "nombre_de_places_restantes"),
    },
    {
      key: "6",
      name: "Nombre d'inscrits",
      fieldName: "nombre_inscrits_valides",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <Text>{item.nombre_inscrits_valides}</Text>,
    },
    {
      key: "7",
      name: "Nombre de demandes d'inscription",
      fieldName: "nombre_inscrits_en_attente_validation",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => (
        <Text>{item.nombre_inscrits_en_attente_validation}</Text>
      ),
    },
    {
      key: "8",
      name: "Actions",
      fieldName: "actions",
      maxWidth: 100,
      minWidth: 100,
      isResizable: true,
      onRender: (item: any) => {
        const canInscrireCandidatureRulesForAllUsers =
          item.nombre_de_places_restantes && !item.est_cloture;

        const canDeleteJury =
          item.statut == 1 && //Jury OUVERT
          item.nombre_inscrits_valides == 0 &&
          item.nombre_inscrits_en_attente_validation == 0;

        return (
          <Stack horizontal wrap>
            <Can I={userAccessEnum.VISUALISER_JURY}>
              <Link
                style={{ marginLeft: 5 }}
                styles={commonButtonStyles.buttonLink}
                to={`/admin/jurys/${item.uuid}/candidats`}
                as={RouterLink}
              >
                Visualiser
              </Link>
            </Can>
            <Can I={userAccessEnum.CREER_JURYS_CERTIFICATION}>
              <Link
                to={`/admin/jurys/${item.uuid}`}
                as={RouterLink}
                style={{ marginLeft: 5 }}
                styles={commonButtonStyles.buttonLink}
              >
                Editer
              </Link>
            </Can>
            {(canInscrireCandidatureEvenIfDateInscriptionEchuProfil ||
              DateTime.fromISO(item.date_limite_inscription_candidat).startOf(
                "day"
              ) >= DateTime.fromISO(DateTime.local().toISO()).startOf("day")) &&
              canInscrireCandidatureRulesForAllUsers && (
                <Can I={userAccessEnum.INSCRIRE_AU_JURY_CERTIFICATION}>
                  <Link
                    styles={commonButtonStyles.buttonLink}
                    style={{ marginLeft: 5 }}
                    onClick={() =>
                      actionsPage.openInscrireCandidaturesModal(item)
                    }
                  >
                    Inscrire des candidats
                  </Link>
                </Can>
              )}
            {isAdmin && canDeleteJury && (
              <Link
                onClick={() => actionsPage.openDeleteJuryModal(item)}
                styles={commonButtonStyles.buttonLink}
                style={{ marginLeft: 5, marginTop: 5 }}
              >
                Supprimer
              </Link>
            )}
          </Stack>
        );
      },
    },
  ].filter((col: any) =>
    accessActions.canI({
      action: branchAccessEnum.OBLIGER_VALIDATION_BRANCHE_POUR_INSCRIPTION_JURY,
    }).granted
      ? col
      : col.fieldName != "nombre_inscrit_en_attente_validation"
  );

  return (
    <Stack>
      <Page title={labels.JURY_TITRE} explain={labels.JURY_SOUS_TITRE}>
        <Can I={userAccessEnum.CREER_JURYS_CERTIFICATION}>
          <CreateLink text={labels.JURY_BTN_CREER} to="/admin/jurys/creer" />
        </Can>

        <JuryListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={jurys?.meta?.current_page || 1}
          totalPages={Math.ceil(jurys?.meta?.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default JuryList;
