import { DatePicker, Label, Stack, TextField } from "@fluentui/react";
import { DateTime } from "luxon";
import { Controller } from "react-hook-form";
import Can from "../../../Common/Can/Can";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import { branchAccessEnum, userAccessEnum } from "../../../config/accessEnum";
import { strings } from "../../../config/datePickerConfig";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../../hooks/candidature/useCandidatureWorkflow";
import useCreateurCandidature from "../../../hooks/certifications/useCreateurCandidature";
import { commonInputStyles } from "../../../styles";
import tools from "../../../utils/tools";
import CandidatSection from "../Common/CandidatSection";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import CertificationSection from "../Common/CertificationSection";
import OptionsBlocsSection from "../Common/OptionsBlocsSection";
import useFetchCandidature from "../useFetchCandidature";
import { useAvisFormateur } from "./useAvisFormateur";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";
import BoutonDemanderMEC from "../Common/MiseEnConformite/BoutonDemanderMEC";
import useMiseEnConfirmite from "../Common/MiseEnConformite/useMiseEnConfirmite";
import BoutonEnregistrerMEC from "../Common/MiseEnConformite/BoutonEnregistrerMEC";
import DynamicFieldForm from "../../../Common/DynamicFieldForm/DynamicFieldForm";
import { useEffect } from "react";
import useUrlParams from "../useUrlParams";
import candidatureAdapters from "../../../adapters/candidatureAdapters";
import useConstants from "../../../hooks/useConstants";
import useDynamicFields from "../../../hooks/dynamicFields/useDynamicFields";

const AvisFormateur = () => {
  const {
    isThisPageStatusBeforeCandidatureStatus,
    isReferentielStillEditable,
  } = useCandidatureWorkflow();
  const {
    candidature,
    reactHookProps,
    actions,
    isSaving,
    candidatureActions,
    isSuccessEnregistrementAvis,
  } = useAvisFormateur();
  const { isAnyKindOfAdmin } = useInfoProfilUser();
  const { isMyOF } = useCreateurCandidature();
  const { actions: accessActions } = useAccessSettings();
  const { id_candidature } = useUrlParams();
  const { constants } = useConstants();
  const fetchCandidature = useFetchCandidature(reactHookProps);
  const { isEditMode, mecActions } = useMiseEnConfirmite();
  const { dynamicFields } = useDynamicFields();

  const fetchModifiedCandidature = async () => {
    const candidatureModified = await candidatureActions.getCandidatureDetail(
      id_candidature
    );
    reactHookProps.reset(
      candidatureAdapters.transformForBO(
        candidatureModified,
        constants,
        false,
        dynamicFields
      )
    );
  };

  useEffect(() => {
    if (id_candidature && isSuccessEnregistrementAvis) {
      fetchModifiedCandidature();
    }
  }, [isSuccessEnregistrementAvis]);

  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? isReferentielStillEditable(candidature.statut, ["positionnement"])
      ? userAccessEnum.MODIFIER_AVIS_FORMATEUR
      : userAccessEnum.NO_ACCESS_EVEN_ADMIN
    : userAccessEnum.CREER_AVIS_FORMATEUR;

  const inputsDisabled =
    !accessActions.canI({
      action: [applicableEditPermission],
    }).granted ||
    (!isMyOF(candidature.of_lie?.uuid) && !isAnyKindOfAdmin);

  const onSave = (data: any, estEnregistre: boolean) => {
    if (isEditMode) {
      mecActions.onSaveMEC({
        ...data,
        avis_formateur: {
          id: data.avis_formateur_id ?? null,
          nom: data.nom_formateur,
          prenom: data.prenom_formateur,
          email: data.email_formateur,
          date_debut_formation: data.date_debut_formation,
          date_fin_formation: data.date_fin_formation,
          avis: data.avis_formateur,
          est_enregistre: estEnregistre,
        },
      });
    } else {
      actions.onSave(data, estEnregistre);
    }
  };

  const onSubmit = (e: any, estEnregistre: any) => {
    reactHookProps.clearErrors();

    reactHookProps.handleSubmit((data) => onSave(data, estEnregistre))(e);
  };

  const editableInputNames = mecActions.onGetEditableInputNames();

  return (
    <Stack>
      <CandidatureInfoHeaderWrapper>
        <CandidatSection candidat={candidature.candidat} />
        <CertificationSection certification={candidature.certification} />
      </CandidatureInfoHeaderWrapper>
      {inputsDisabled && (
        <Can I={[userAccessEnum.MODIFIER_AVIS_FORMATEUR]}>
          <BoutonDemanderMEC
            statut={StatutCandidature.AVIS_FORMATEUR}
            statut_candidature={candidature.statut}
            onAfterValidate={fetchCandidature.refrechCandidature}
            createurUuid={candidature.createur?.uuid}
          />
        </Can>
      )}
      <form>
        <OptionsBlocsSection
          candidature={candidature}
          statutCandidature={StatutCandidature.AVIS_FORMATEUR}
          reactHookProps={reactHookProps}
        />

        <FormSection sectionTitle="Avis du formateur">
          <Stack
            style={{ marginBottom: 24 }}
            horizontalAlign="start"
            horizontal
            wrap
            className="ms-Grid"
          >
            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label={"Nom :"}
                    onRenderLabel={({ label }: any) => (
                      <Stack horizontal verticalAlign="center">
                        {label} &nbsp;
                        <i
                          style={{
                            fontWeight: 300,
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          (requis pour la validation de l'avis)
                        </i>
                      </Stack>
                    )}
                    {...field}
                    placeholder="Ex : Doe"
                    errorMessage={
                      (
                        (reactHookProps.formState.errors as any).avis_formateur
                          ?.nom as any
                      )?.message
                    }
                    disabled={
                      inputsDisabled && !editableInputNames.includes(field.name)
                    }
                  />
                )}
                name="nom_formateur"
                control={reactHookProps.control}
              />
            </Stack.Item>
            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label="Prénom :"
                    onRenderLabel={({ label }: any) => (
                      <Stack horizontal verticalAlign="center">
                        {label} &nbsp;
                        <i
                          style={{
                            fontWeight: 300,
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          (requis pour la validation de l'avis)
                        </i>
                      </Stack>
                    )}
                    {...field}
                    placeholder="Ex : John"
                    errorMessage={
                      (
                        (reactHookProps.formState.errors.avis_formateur as any)
                          ?.prenom as any
                      )?.message
                    }
                    disabled={
                      inputsDisabled && !editableInputNames.includes(field.name)
                    }
                  />
                )}
                name="prenom_formateur"
                control={reactHookProps.control}
              />
            </Stack.Item>
            <Stack.Item
              className="ms-Grid-col ms-sm12"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label="Email :"
                    onRenderLabel={({ label }: any) => (
                      <Stack horizontal verticalAlign="center">
                        {label} &nbsp;
                        <i
                          style={{
                            fontWeight: 300,
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          (requis pour la validation de l'avis)
                        </i>
                      </Stack>
                    )}
                    {...field}
                    placeholder="Ex : john.doe@email.com"
                    errorMessage={
                      (
                        (reactHookProps.formState.errors.avis_formateur as any)
                          ?.email as any
                      )?.message
                    }
                    disabled={
                      inputsDisabled && !editableInputNames.includes(field.name)
                    }
                  />
                )}
                name="email_formateur"
                control={reactHookProps.control}
              />
            </Stack.Item>

            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Label
                htmlFor="date_debut_formation"
                styles={commonInputStyles.label}
              >
                Date de début de formation du candidat :
              </Label>
              <Controller
                render={({ field: { onChange, name, value } }) => (
                  <DatePicker
                    formatDate={(date?: any): any =>
                      date ? `${tools.reformateDateFromJsDate(date)}` : null
                    }
                    strings={strings}
                    showGoToToday={false}
                    maxDate={new Date()}
                    value={
                      reactHookProps.watch("date_debut_formation")
                        ? new Date(reactHookProps.watch("date_debut_formation"))
                        : undefined
                    }
                    styles={commonInputStyles.dataPicker}
                    onSelectDate={(date: any) => {
                      if (date) {
                        onChange(
                          DateTime.fromJSDate(date)
                            .set({ hour: 12 })
                            .toUTC()
                            .toISO()
                        );
                      }
                    }}
                    disabled={
                      inputsDisabled && !editableInputNames.includes(name)
                    }
                    placeholder="dd/mm/yyyy"
                    allowTextInput
                    parseDateFromString={(dateStr: any) =>
                      tools.parseDateFromStringDatePicker(dateStr)
                    }
                  />
                )}
                name="date_debut_formation"
                control={reactHookProps.control}
              />
            </Stack.Item>

            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Label
                htmlFor="date_fin_formation"
                styles={commonInputStyles.label}
              >
                Date de fin de formation du candidat:
              </Label>
              <Controller
                render={({ field: { onChange, name, value } }) => (
                  <DatePicker
                    formatDate={(date?: any): any =>
                      date ? `${tools.reformateDateFromJsDate(date)}` : null
                    }
                    strings={strings}
                    showGoToToday={false}
                    maxDate={new Date()}
                    value={
                      reactHookProps.watch("date_fin_formation")
                        ? new Date(reactHookProps.watch("date_fin_formation"))
                        : undefined
                    }
                    styles={commonInputStyles.dataPicker}
                    onSelectDate={(date: any) => {
                      if (date) {
                        onChange(
                          DateTime.fromJSDate(date)
                            .set({ hour: 12 })
                            .toUTC()
                            .toISO()
                        );
                      }
                    }}
                    disabled={
                      inputsDisabled && !editableInputNames.includes(name)
                    }
                    placeholder="dd/mm/yyyy"
                    allowTextInput
                    parseDateFromString={(dateStr: any) =>
                      tools.parseDateFromStringDatePicker(dateStr)
                    }
                  />
                )}
                name="date_fin_formation"
                control={reactHookProps.control}
              />
            </Stack.Item>

            <Stack.Item
              className="ms-Grid-col ms-sm12"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label="Avis du formateur (6000 caractères max) :"
                    placeholder="Ex : avis du formateur"
                    onRenderLabel={({ label }: any) => (
                      <Stack horizontal verticalAlign="center">
                        {label} &nbsp;
                        <i
                          style={{
                            fontWeight: 300,
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          (requis pour la validation de l'avis)
                        </i>
                      </Stack>
                    )}
                    multiline
                    rows={6}
                    maxLength={6000}
                    {...field}
                    disabled={
                      inputsDisabled && !editableInputNames.includes(field.name)
                    }
                    errorMessage={
                      (
                        (reactHookProps.formState.errors.avis_formateur as any)
                          ?.avis as any
                      )?.message
                    }
                  />
                )}
                control={reactHookProps.control}
                name="avis_formateur"
              />
            </Stack.Item>
          </Stack>
        </FormSection>

        <DynamicFieldForm
          statut={StatutCandidature.AVIS_FORMATEUR}
          reactHookProps={reactHookProps}
          disabled={inputsDisabled && !isEditMode}
        />

        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginTop: 20 }}
        >
          <CancelButton />

          <Can I={applicableEditPermission}>
            <LoadButton
              text={"Enregistrer l'avis formateur"}
              isLoading={isSaving}
              type="button"
              onClick={(e: any) => onSubmit(e, true)}
            />
            <LoadButton
              text={"Valider l'avis formateur"}
              isLoading={isSaving}
              type="button"
              onClick={(e: any) => onSubmit(e, false)}
            />
          </Can>
          <BoutonEnregistrerMEC
            onClick={(e: any) => onSubmit(e, false)}
            reactHookProps={reactHookProps}
            onAfterValidate={fetchCandidature.refrechCandidature}
            typeBtn="button"
          />
        </Stack>
      </form>
    </Stack>
  );
};

export default AvisFormateur;
