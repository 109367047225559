import { MessageBar, Stack } from "@fluentui/react";
import Can from "../../../Common/Can/Can";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import { userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import useFetchCandidature from "../useFetchCandidature";

import CertificationSection from "./FormSections/CertificationSection";
import GeneralInformationsSection from "./FormSections/GeneralInformationsSection";

import { useAtom } from "jotai";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import FormSection from "../../../Common/FormSection/FormSection";
import { StatutCandidature } from "../../../hooks/candidature/useCandidatureWorkflow";
import { MessageBarStyles } from "../../../styles";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import BoutonDemanderMEC from "../Common/MiseEnConformite/BoutonDemanderMEC";
import BoutonEnregistrerMEC from "../Common/MiseEnConformite/BoutonEnregistrerMEC";
import useMiseEnConfirmite from "../Common/MiseEnConformite/useMiseEnConfirmite";
import DomaineInformationSection from "./FormSections/DomaineInformationSection";
import EntitiesLinkedToCandidatSection from "./FormSections/EntitiesLinkedToCandidatSection";
import SearchCandidatSection from "./FormSections/SearchCandidatSection";
import useCreationCandidature, {
  CandidatureContext,
} from "./useCreationCandidature";
import { ImportantMessageBarStyles } from "../../../styles/MessageBar.styles";

function CreationCandidature() {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);
  const queryClient = useQueryClient();
  const {
    userSelectedBranch,
    reactHookProps,
    conditionnalDisplayVariables,
    isChecked,
    searchResult,
    actions,
    candidature,
    isSaving,
  } = useCreationCandidature();
  const { isCandidateInvitation, isGroupCompanyUser, isGroupOfUser } =
    conditionnalDisplayVariables;
  const { handleSubmit, clearErrors } = reactHookProps;
  const { actions: accessActions } = useAccessSettings();

  const fetchCandidature = useFetchCandidature(reactHookProps);
  const { isEditMode, mecActions } = useMiseEnConfirmite();

  const inputsDisabled =
    !accessActions.canI({ action: [userAccessEnum.SAISIR_CANDIDATURE] })
      .granted || candidature.uuid;

  const onSubmit = (e: any) => {
    clearErrors();
    if (isEditMode) {
      handleSubmit(mecActions.onSaveMEC)(e);
    } else {
      handleSubmit(actions.onSave)(e);
    }
  };

  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: "certifications_candidature" });
    };
  }, []);

  //INFO: champs non requis a la creation mais requis lors d'une MEC
  const requiredInputs =
    candidature.statut !== "creation_candidature"
      ? ["candidat.code_postal_naissance", "candidat.code_postal"]
      : [];

  const editableInputNames = mecActions.onGetEditableInputNames();

  return (
    <CandidatureContext.Provider
      value={{
        isChecked,
        searchResult,
        reactHookProps,
        conditionnalDisplayVariables,
        actions,
        userSelectedBranch,
      }}
    >
      <Stack style={{ marginTop: 0 }}>
        {inputsDisabled && (
          <Can I={[userAccessEnum.SAISIR_CANDIDATURE]}>
            <BoutonDemanderMEC
              statut={StatutCandidature.CREATION_CANDIDATURE}
              statut_candidature={candidature.statut}
              onAfterValidate={fetchCandidature.refrechCandidature}
              createurUuid={candidature.createur?.uuid}
            />
          </Can>
        )}
        <form onSubmit={onSubmit}>
          {userSelectedBranch.gestion_par_domaine && (
            <DomaineInformationSection disabled={inputsDisabled} />
          )}

          {(!userSelectedBranch.gestion_par_domaine ||
            (userSelectedBranch.gestion_par_domaine &&
              reactHookProps.watch("domaine"))) && (
            <>
              <FormSection sectionTitle="Informations du candidat">
                <SearchCandidatSection
                  disabled={inputsDisabled}
                  enabledInputs={editableInputNames}
                />
              </FormSection>
              {(isChecked || inputsDisabled || candidature.uuid) &&
                (!isCandidateInvitation || inputsDisabled) && (
                  <FormSection sectionTitle="Informations complémentaires sur le candidat">
                    {isCandidateInvitation && (
                      <MessageBar styles={MessageBarStyles}>
                        Le paramètre de branche "Candidat est partie prenante du
                        processus de certification" est activé. Le candidat
                        renseigne lui-même ses informations complémentaires via
                        son profil accessible dans sa fiche individuelle.
                      </MessageBar>
                    )}
                    <MessageBar styles={ImportantMessageBarStyles}>
                      Les champs ci-dessous sont facultatifs à cette étape.
                      Cependant, ces informations hormis le téléphone
                      professionnel devront être obligatoirement renseignées à
                      l’étape de transmission du dossier. Vous avez la
                      possibilité de les compléter par la suite via la fiche
                      individuelle du candidat.
                    </MessageBar>
                    <GeneralInformationsSection
                      disabled={inputsDisabled}
                      enabledInputs={editableInputNames}
                      withContainer={false}
                      requiredInputs={requiredInputs}
                    />
                  </FormSection>
                )}
              {(isGroupCompanyUser || isGroupOfUser) && (
                <FormSection sectionTitle="Entités liées au candidat">
                  <EntitiesLinkedToCandidatSection disabled={inputsDisabled} />
                </FormSection>
              )}
              <CertificationSection
                disabled={inputsDisabled}
                enabledInputs={editableInputNames}
              />
            </>
          )}

          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ marginTop: 20 }}
          >
            <Stack.Item>
              {!isOpenedInModal && <CancelButton text={"Retour"} />}
            </Stack.Item>
            {!candidature.uuid && isChecked && (
              <Can I={[userAccessEnum.SAISIR_CANDIDATURE]}>
                <LoadButton
                  text={"Créer le profil du candidat"}
                  isLoading={isSaving}
                />
              </Can>
            )}
            <BoutonEnregistrerMEC
              reactHookProps={reactHookProps}
              onAfterValidate={fetchCandidature.refrechCandidature}
            />
          </Stack>
        </form>
      </Stack>
    </CandidatureContext.Provider>
  );
}

export default CreationCandidature;
